<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div class="sidebar-shop" :class="{ show: mqShallShowLeftSidebar }">
        <b-row>
          <b-col cols="6">
            <h6 class="filter-heading d-none d-lg-block">
              Filters
            </h6>
          </b-col>
          <b-col cols="6">
            <h6 class="filter-heading d-none d-lg-block text-right">
              <b-link @click="filtersClear()">Clear</b-link>
            </h6>
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <b-card>
          <!-- Filters -->

          <div class="product-categories">
            <b-form-checkbox-group v-model="filters.carTypes" :reduce="(val) => val.id" label="text" :options="filterOptions.carTypes" switches class="demo-inline-spacing custom-control-success" />
          </div>

          <div class="product-categories">
            <h6 class="filter-title">
              Model Year
            </h6>
            <v-select v-model="filters.modelYears" :reduce="(val) => val.id" multiple label="value" :options="filterOptions.modelYears" />
          </div>

          <div class="product-categories">
            <h6 class="filter-title">
              Make
            </h6>
            <v-select v-model="filters.makes" :reduce="(val) => val.id" multiple label="value" :options="filterOptions.makes" />
          </div>

          <div class="product-categories">
            <h6 class="filter-title">
              Model
            </h6>
            <v-select :disabled="filters.makes.length == 0" v-model="filters.models" :reduce="(val) => val.id" multiple label="value" :options="filterOptions.models" />
          </div>

          <!-- Price Slider -->
          <div class="price-slider">
            <h6 class="filter-title">
              Price Range
            </h6>
            <vue-slider class="mt-3" :min="0" :max="500000" v-if="filters.priceRange[1] != 500000" :interval="1000" :tooltip="'always'" :tooltip-placement="['top', 'bottom']" :tooltip-formatter="formatter2" v-model="filters.priceRange" />
            <vue-slider class="mt-3" v-if="filters.priceRange[1] == 500000" :min="0" :max="500000" :interval="1000" :tooltip="'always'" :tooltip-placement="['top', 'bottom']" :tooltip-formatter="formatter3" v-model="filters.priceRange" />
          </div>

          <app-collapse class="mt-3">
            <app-collapse-item title="Exterior Colour">
              <b-form-group>
                <b-form-checkbox v-for="option in filterOptions.extColours" value-field="value" :key="option.id" v-model="filters.extColours" :value="option.id" class="extColour">
                  <span class="mt-2"> <span class="mr-1" style="width:50px; height:50px; border-radius: 100px; border: 1px solid #e5e5e5;" :style="{ backgroundColor: option.value }"> &nbsp; &nbsp; &nbsp;</span> {{ option.value }}</span>
                </b-form-checkbox>
              </b-form-group>
            </app-collapse-item>
            <app-collapse-item title="Interior Colour">
              <b-form-group>
                <b-form-checkbox v-for="option in filterOptions.intColours" value-field="value" :key="option.id" v-model="filters.intColours" :value="option.id" class="extColour">
                  <span class="mt-2"> <span class="mr-1" style="width:50px; height:50px; border-radius: 100px; border: 1px solid #e5e5e5;" :style="{ backgroundColor: option.value }"> &nbsp; &nbsp; &nbsp;</span> {{ option.value }}</span>
                </b-form-checkbox>
              </b-form-group>
            </app-collapse-item>
            <app-collapse-item title="Body Style">
              <b-form-group>
                <b-form-checkbox v-for="option in filterOptions.bodyTypes" value-field="value" :key="option.id" v-model="filters.bodyTypes" :value="option.id" class="extColour"> <span class="mt-2"> </span>{{ option.value }} </b-form-checkbox>
              </b-form-group>
            </app-collapse-item>

            <app-collapse-item title="Drive Train">
              <b-form-group>
                <b-form-checkbox v-for="option in filterOptions.drivetrains" value-field="value" :key="option.id" v-model="filters.drivetrains" :value="option.id" class="extColour"> <span class="mt-2"> </span>{{ option.value }} </b-form-checkbox>
              </b-form-group>
            </app-collapse-item>

            <app-collapse-item title="Transmission">
              <b-form-group>
                <b-form-checkbox v-for="option in filterOptions.transmissions" value-field="value" :key="option.id" v-model="filters.transmissions" :value="option.id" class="extColour"> <span class="mt-2"> </span>{{ option.value }} </b-form-checkbox>
              </b-form-group>
            </app-collapse-item>

            <app-collapse-item title="Fuel Type">
              <b-form-group>
                <b-form-checkbox v-for="option in filterOptions.fuelTypes" value-field="value" :key="option.id" v-model="filters.fuelTypes" :value="option.id" class="extColour"> <span class="mt-2"> </span>{{ option.value }} </b-form-checkbox>
              </b-form-group>
            </app-collapse-item>
          </app-collapse>
        </b-card>
      </div>
    </div>

    <div class="body-content-overlay" :class="{ show: mqShallShowLeftSidebar }" @click="$emit('update:mq-shall-show-left-sidebar', false)" />
  </div>
</template>

<script>
import { BRow, BCol, BCard, BFormRadioGroup, BLink, BFormCheckbox, BFormCheckboxGroup, BFormGroup, BCardText } from 'bootstrap-vue';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import VueSlider from 'vue-slider-component';
import vSelect from 'vue-select';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BFormRadioGroup,
    BLink,
    BCard,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BCardText,
    vSelect,
    AppCollapse,
    AppCollapseItem,

    // 3rd Party
    VueSlider,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },

  data: function() {
    return {
      formatter2: (v) => `$${('' + v).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      formatter3: (v) => `$${('' + v).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}+`,
      marks2: {
        '0': {
          label: '$0',
          style: {
            width: '8px',
            height: '8px',
            display: 'block',
            backgroundColor: '#69c0ff',
            transform: 'translate(-2px, -2px)',
          },
          labelStyle: {
            color: '#69c0ff',
          },
        },

        '100000': {
          label: '$100.000',
          style: {
            width: '8px',
            height: '8px',
            display: 'block',
            backgroundColor: 'red',
            transform: 'translate(-2px, -2px)',
          },
          labelStyle: {
            color: 'red',
          },
        },
      },
    };
  },

  methods: {
    filtersClear() {
      this.$emit('update:filters', {
        q: '',
        priceRangeDefined: 'all',
        priceRange: [0, 500000],
        makes: [],
        models: [],
        modelYears: [],
        carTypes: [],
        bodyTypes: [],
        page: 1,
        perPage: 9,
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.extColour {
  margin-bottom: 0.75rem;
}
</style>
