import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import { title } from '@core/utils/filter';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useInventoryList() {
  // Use toast

  const toast = useToast();

  const refInventoryListTable = ref(null);

  // { key: 'actions', thClass: 'text-right', tdClass: 'text-right' },
  const tableColumns = [
    { key: 'id', label: 'STOCK NUMBER', sortable: true, class: 'text-center', thStyle: 'width: 10%' },
    { key: 'vehiclePicture', sortable: false, class: 'text-center', thStyle: 'width: 10%' },
    { key: 'description', sortable: false, class: 'text-center', thStyle: 'width: 30%' },
    { key: 'condition', sortable: true, class: 'text-center', thStyle: 'width: 10%' },
    // { key: 'make', sortable: false, class: 'text-center', thStyle: 'width: 15%' },
    // { key: 'model', sortable: false, class: 'text-center', thStyle: 'width: 15%' },
    // { key: 'modelYear', sortable: true, class: 'text-center', thStyle: 'width: 10%' },
    { key: 'listingPrice', sortable: true, class: 'text-center', thStyle: 'width: 10%' },
    { key: 'status', sortable: true, class: 'text-center', class: 'text-center', thStyle: 'width: 10%' },
    { key: 'Publish', sortable: true, class: 'text-center', class: 'text-center', thStyle: 'width: 10%' },
    { key: 'actions', class: 'text-center', thStyle: 'width: 10%' },
  ];
  const sortBy = ref('id');
  const perPage = ref(10);
  const statusWebsite = ref(null);

  const statusWebsiteOptions = [
    // { text: 'Website Status Selection', value: '' },
    { text: 'Published', value: 'yes' },
    { text: 'Unpublished', value: 'no' },
  ];

  const status = ref(null);

  const statusOptions = [
    // { text: 'Inventory Status Selection', value: '' },
    { text: 'Created', value: 'A' },
    { text: 'Incoming - Consigment', value: 'B' },
    { text: 'In Stock - Consigment', value: 'C' },
    { text: 'Incoming', value: 'D' },
    { text: 'Incoming Order', value: 'I' },
    { text: 'In Stock - Pending Receiving', value: 'E' },
    { text: 'In Stock - Pending Payment', value: 'F' },
    { text: 'In Stock', value: 'G' },

    { text: 'Sold Pending', value: 'PA' },
    { text: 'Delivered - Pending Payment', value: 'PB' },
    { text: 'Sold', value: 'PC' },
    { text: 'Delivered', value: 'PD' },
    { text: 'Closed', value: 'PE' },
  ];

  // <b-badge v-if="data.item.status == 'A'" pill variant="info" class="badge-glow">Created</b-badge>
  // <b-badge v-if="data.item.status == 'B'" pill variant="warning" class="badge-glow">Incoming - Consigment</b-badge>
  // <b-badge v-if="data.item.status == 'C'" pill variant="success" class="badge-glow">In Stock - Consigment</b-badge>
  // <b-badge v-if="data.item.status == 'D'" pill variant="light-info" class="badge-glow">Incoming</b-badge>
  // <b-badge v-if="data.item.status == 'E'" pill variant="light-success" class="badge-glow">In Stock - Pending Receiving</b-badge>
  // <b-badge v-if="data.item.status == 'F'" pill variant="secondary" class="badge-glow">In Stock - Pending Payment</b-badge>
  // <b-badge v-if="data.item.status == 'P'" pill variant="danger" class="badge-glow">Created</b-badge>
  // <b-badge v-if="data.item.status == 'G'" pill variant="primary" class="badge-glow">In Stock</b-badge>

  const totalInventorys = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];

  const searchQuery = ref('');
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const loading = ref(false);

  const dataMeta = computed(() => {
    const localItemsCount = refInventoryListTable.value ? refInventoryListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInventorys.value,
    };
  });

  const refetchData = () => {
    refInventoryListTable.value.refresh();
  };

  watch([perPage, currentPage, searchQuery, roleFilter, planFilter, statusFilter, statusWebsite, status], () => {
    refetchData();
  });

  const fetchInventorys = (ctx, callback) => {
    loading.value = true;
    store
      .dispatch('inventory/fetchInventorys', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        webStatus: statusWebsite.value,
        status: status.value,
      })
      .then((response) => {
        callback(response.data.data);

        totalInventorys.value = response.data.total;
        loading.value = false;
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching inventory list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  //fetchInventorysPublish

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchInventorys,

    tableColumns,
    perPage,

    currentPage,
    totalInventorys,
    dataMeta,
    perPageOptions,
    statusWebsiteOptions,
    statusWebsite,

    status,
    statusOptions,

    searchQuery,
    sortBy,
    isSortDirDesc,
    refInventoryListTable,

    refetchData,
    loading,
    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}

export const useInventoryFiltersSortingAndPagination = () => {
  const filters = ref({
    q: '',
    priceRangeDefined: 'all',
    priceRange: [0, 500000],
    makes: [],
    models: [],
    modelYears: [],
    carTypes: [],
    page: 1,
    perPage: 18,

    extColours: [],
    intColours: [],
    bodyTypes: [],
    chassisMetairels: [],
    drivetrains: [],
    transmissions: [],
    fuelTypes: [],
    places: [],
  });

  const filterOptions = ref({
    makes: [],
    modelYears: [],
    models: [],
    carTypes: [
      { text: 'Used', value: 'used' },
      { text: 'New', value: 'new' },
    ],
    extColours: [],
    intColours: [],
    bodyTypes: [],
    chassisMetairels: [],
    drivetrains: [],
    transmissions: [],
    fuelTypes: [],
    places: [],
  });

  store.dispatch('inventory/getAllDropDowns').then((response) => {
    /* response.data.make.forEach((element) => {
      filterOptions.value.makes.push(element);
    });*/

    filterOptions.value.makes = response.data.make;
    filterOptions.value.modelYears = response.data.modelYear;
    filterOptions.value.extColours = response.data.extColour;
    filterOptions.value.intColours = response.data.intColour;
    filterOptions.value.bodyTypes = response.data.body;
    filterOptions.value.drivetrains = response.data.drivetrain;
    filterOptions.value.transmissions = response.data.transmmission;
    filterOptions.value.fuelTypes = response.data.fuel;
  });

  // Sorting
  const sortBy = ref({ text: 'Last added', value: 'id-desc' });
  const sortByOptions = [
    { text: 'Last added', value: 'id-desc' },
    { text: 'Lowest', value: 'listingPrice-asc' },
    { text: 'Highest', value: 'listingPrice-desc' },
  ];

  return {
    // Filter
    filters,
    filterOptions,

    // Sort
    sortBy,
    sortByOptions,
  };
};

export const useShopUi = () => {
  const itemView = 'list-view';
  const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
  ];

  // Pagination count <= required by pagination component
  const totalProducts = ref(null);

  return {
    itemView,
    itemViewOptions,
    totalProducts,
  };
};

export const useShopRemoteData = () => {
  const products = ref([]);

  const fetchProducts = (...args) => store.dispatch('inventory/fetchInventorysFiltered', ...args);

  return {
    products,
    fetchProducts,
  };
};
