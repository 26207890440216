<template>
  <div style="height: inherit">
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon icon="MenuIcon" class="d-block d-lg-none" size="21" @click="mqShallShowLeftSidebar = true" />
              <div class="search-results">{{ totalProducts }} results found</div>
            </div>
            <div class="view-options d-flex">
              <!-- Sort Button -->
              <b-dropdown v-ripple.400="'rgba(113, 102, 240, 0.15)'" :text="sortBy.text" right variant="outline-primary">
                <b-dropdown-item v-for="sortOption in sortByOptions" :key="sortOption.value" @click="sortBy = sortOption">
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group v-model="itemView" class="ml-1 list item-view-radio-group" buttons size="sm" button-variant="outline-primary">
                <b-form-radio v-for="option in itemViewOptions" :key="option.value" :value="option.value">
                  <feather-icon :icon="option.icon" size="18" />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar 
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input v-model="filters.q" placeholder="Search Product" class="search-product" />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>-->

    <!-- Prodcuts -->
    <section :class="itemView" class="mt-1">
      <b-card v-for="product in products" :key="product.id" class="ecommerce-card" no-body>
        <div class="item-img text-center  " style="display:block;">
          <!-- style="width: 150px;" -->
          <b-link :to="{ name: 'inventory-view', params: { id: product.id } }">
            <b-img fluid class="card-img-top px-1 py-1 rounded" v-if="product.get_images[0]" :src="baseURL + product.get_images[0].name" />
            <b-img fluid class="card-img-top px-1 py-1 rounded" v-if="!product.get_images[0]" src="@/assets/images/no-car.png" />
          </b-link>
        </div>

        <!-- Product Details -->
        <b-card-body>
          <h6 class="item-name">
            <b-link class="text-body" :to="{ name: 'inventory-view', params: { id: product.id } }"> {{ product.get_model_year ? product.get_model_year.value : '--' }} - {{ product.get_make ? product.get_make.value : '--' }} - {{ product.get_model ? product.get_model.value : '--' }} </b-link>
          </h6>

          <div>
            <h6 class="item-price text-success">
              <money-format :value="product.listingPrice" :subunits-value="false" :hide-subunits="false"> </money-format>
            </h6>
          </div>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price"><money-format :value="product.listingPrice" :subunits-value="false" :hide-subunits="false"> </money-format></h4>
            </div>
          </div>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination v-model="filters.page" :total-rows="totalProducts" :per-page="filters.perPage" first-number align="center" last-number prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <inventory-list-filter :filters.sync="filters" :filter-options="filterOptions" ref="p1" :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar" />
    </portal>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BInputGroupAppend, BFormInput, BCard, BCardBody, BLink, BImg, BCardText, BButton, BPagination } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { onUnmounted, watch } from '@vue/composition-api';

import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app';
//import ShopLeftFilterSidebar from './ECommerceShopLeftFilterSidebar.vue';
import { useInventoryFiltersSortingAndPagination, useShopUi, useShopRemoteData } from './useInventoryList';
import store from '@/store';
import inventoryStoreModule from '../inventoryStoreModule';
import InventoryListFilter from './InventoryListFilter.vue';
import MoneyFormat from 'vue-money-format';
import { filter } from 'postcss-rtl/lib/affected-props';

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    InventoryListFilter,
    MoneyFormat,

    // SFC
    //ShopLeftFilterSidebar,
  },
  setup() {
    const INVENTORY_APP_STORE_MODULE_NAME = 'inventory';

    // Register module
    if (!store.hasModule(INVENTORY_APP_STORE_MODULE_NAME)) store.registerModule(INVENTORY_APP_STORE_MODULE_NAME, inventoryStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVENTORY_APP_STORE_MODULE_NAME)) store.unregisterModule(INVENTORY_APP_STORE_MODULE_NAME);
    });

    const { filters, filterOptions, sortBy, sortByOptions } = useInventoryFiltersSortingAndPagination();

    const { itemView, itemViewOptions, totalProducts } = useShopUi();

    const { products, fetchProducts } = useShopRemoteData();

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    let makeCount = 0;
    var sliderCount = 0;
    var lastQuery = 0;
    var baseURL = store.state.app.baseURL;
    var priceOne = 0;
    var priceSec = 500000;

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      fetchProducts({
        q: filters.value.q,
        sortBy: sortBy.value.value,
        page: filters.value.page,
        perPage: filters.value.perPage,
        priceRange: filters.value.priceRange,
        makes: filters.value.makes,
        modelYears: filters.value.modelYears,
        models: filters.value.models,
        carTypes: filters.value.carTypes,
        extColours: filters.value.extColours,
        intColours: filters.value.intColours,
        bodyTypes: filters.value.bodyTypes,
        chassisMetairels: filters.value.chassisMetairels,
        drivetrains: filters.value.drivetrains,
        transmissions: filters.value.transmissions,
        fuelTypes: filters.value.fuelTypes,
        places: filters.value.places,
      }).then((response) => {
        products.value = response.data.data;
        totalProducts.value = response.data.total;

        setTimeout(() => {
          if (lastQuery > 1) {
            updateModelsList(filters.value.makes);
            fetchShopProducts();
            lastQuery = 0;
            sliderCount = 0;
          }
        }, 1000);
      });
    };

    fetchShopProducts();

    watch(
      [filters, sortBy],
      () => {
        if (filters.value.priceRange[0] == priceOne && filters.value.priceRange[1] == priceSec) {
          updateModelsList(filters.value.makes);
          fetchShopProducts();
        } else {
          priceOne = filters.value.priceRange[0];
          priceSec = filters.value.priceRange[1];
        }
        if (sliderCount == 0) {
          updateModelsList(filters.value.makes);
          fetchShopProducts();

          sliderCount++;
        } else {
          sliderCount++;
        }
        lastQuery++;
      },
      {
        deep: true,
      }
    );

    const updateModelsList = (makeIds) => {
      if (makeIds.length != makeCount) {
        makeCount = makeIds.length;
        filterOptions.value.models = [];
        filters.value.models = [];
        makeIds.forEach((element) => {
          store.dispatch('inventory/getModelByMake', element).then((response) => {
            response.data.forEach((model) => {
              filterOptions.value.models.push(model);
            });
          });
        });
      } else {
      }
    };

    return {
      // useInventoryFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,

      // useShopRemoteData
      products,
      baseURL,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
